// src/components/ChatInterface.js

import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  VStack,
  Input,
  Button,
  Text,
  useToast,
  List,
  ListItem,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import api from "../api/axios";
import { getToken } from "../utils/auth";

const ChatInterface = () => {
  const [input, setInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [categories, setCategories] = useState([]);
  const inputRef = useRef(null);
  const toast = useToast();

  const getUser = async () => {
    const token = getToken();
    if (!token) {
      return null;
    }
    try {
      const response = await api.get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  const fetchTransactions = useCallback(async () => {
    try {
      const token = getToken();
      const response = await api.get("/transactions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && Array.isArray(response.data.transactions)) {
        setTransactions(response.data.transactions);
        updateCategories(response.data.transactions);
      } else {
        console.error("Unexpected response format:", response.data);
        toast({
          title: "Error fetching transactions",
          description: "Received unexpected data format",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast({
        title: "Error fetching transactions",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    const initializeComponent = async () => {
      const user = await getUser();
      if (user) {
        fetchTransactions();
      }
    };

    initializeComponent();
  }, [fetchTransactions]);

  useEffect(() => {
    if (input.trim()) {
      const words = input.trim().split(" ");
      const lastWord = words[words.length - 1].toLowerCase();
      const filteredSuggestions = categories
        .filter((cat) => cat.toLowerCase().includes(lastWord))
        .slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [input, categories]);
  
  const updateCategories = (transactions) => {
    if (!Array.isArray(transactions)) {
      console.error("updateCategories: transactions is not an array", transactions);
      return;
    }
    const categoryCount = {};
    transactions.forEach((transaction) => {
      if (transaction && transaction.category) {
        categoryCount[transaction.category] = (categoryCount[transaction.category] || 0) + 1;
      }
    });
    const sortedCategories = Object.keys(categoryCount).sort(
      (a, b) => categoryCount[b] - categoryCount[a]
    );
    setCategories(sortedCategories);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async () => {
    const parts = input.trim().split(" ");
    let amount, category, description;

    if (parts.length >= 2) {
      if (!isNaN(parts[0])) {
        amount = parseFloat(parts[0]);
        category = parts[1];
        description = parts.slice(2).join(" ");
      } else if (!isNaN(parts[parts.length - 1])) {
        amount = parseFloat(parts[parts.length - 1]);
        category = parts[0];
        description = parts.slice(1, -1).join(" ");
      }
    }

    if (amount && category) {
      try {
        const token = getToken();
        const newTransaction = {
          date: new Date(),
          amount,
          category,
          description,
          type: "expense",
        };
        const response = await api.post("/transactions", newTransaction, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        toast({
          title: "Transaction added",
          description: `${amount} for ${category}`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setInput("");
        setTransactions([response.data, ...transactions]);
        updateCategories([...transactions, response.data]);
      } catch (error) {
        toast({
          title: "Error adding transaction",
          description: error.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Invalid input",
        description: "Please enter an amount and category",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    } else if (e.key === "Tab" && suggestions.length > 0) {
      e.preventDefault();
      handleSuggestionClick(suggestions[0]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const words = input.trim().split(" ");
    words[words.length - 1] = suggestion;
    setInput(words.join(" ") + " ");
    inputRef.current.focus();
  };

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <InputGroup size="md">
          <Input
            ref={inputRef}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="50 groceries"
            pr="4.5rem"
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleSubmit}>
              Add
            </Button>
          </InputRightElement>
        </InputGroup>

        {suggestions.length > 0 && (
          <Box borderWidth={1} borderRadius="md" p={2}>
            <Text fontSize="sm" mb={2}>
              Suggestions:
            </Text>
            <List spacing={1}>
              {suggestions.map((suggestion, index) => (
                <ListItem
                  key={index}
                  cursor="pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                  _hover={{ bg: "gray.100" }}
                  p={1}
                >
                  {suggestion}
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Text fontSize="sm" color="gray.500">
          Tip: Type amount and category (e.g., "50 groceries" or "lunch 15.50").
        </Text>

        <Box>
          <Text fontWeight="bold" mb={2}>
            Recent Transactions:
          </Text>
          {transactions.length > 0 ? (
            <List spacing={2}>
              {transactions.slice(0, 5).map((transaction) => (
                <ListItem key={transaction._id}>
                  {`${transaction.amount} ${transaction.category} - ${
                    transaction.description || "No description"
                  }`}
                </ListItem>
              ))}
            </List>
          ) : (
            <Text>No recent transactions.</Text>
          )}
        </Box>
      </VStack>
    </Box>
  );
};

export default ChatInterface;
