import React, { useEffect } from "react";
import { Box, Heading, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import api from "../api/axios";
import ChatInterface from "./ChatInterface";
import { getToken } from "../utils/auth"

const AddTransaction = () => {
  const navigate = useNavigate();
  const toast = useToast();

    useEffect(() => {
      const token = getToken();
      if (!token) {
        navigate("/login");
      }
    }, [navigate]);
  
    const handleTransactionComplete = async (transaction) => {
      try {
        console.log("Sending transaction:", transaction);
        const token = getToken();
        const response = await api.post("/transactions", transaction, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Server response:", response);
        toast({
          title: "Transaction added",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/transactions");
      } catch (error) {
        console.error("Error details:", error);
        if (error.response) {
          console.error("Error status:", error.response.status);
          console.error("Error data:", error.response.data);
        }
        toast({
          title: "Error adding transaction",
          description: error.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    return (
      <Box maxWidth="500px" margin="auto">
        <Heading mb={4} mt={4}>Add Transaction</Heading>
        <ChatInterface onTransactionComplete={handleTransactionComplete} />
      </Box>
    );
  };
  
  export default AddTransaction;
