import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import api from "../api/axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getToken } from "../utils/auth";
import { ErrorBoundary } from 'react-error-boundary';

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];

const Dashboard = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  const getUser = async () => {
    const token = getToken();
    if (!token) {
      return null;
    }
    try {
      const response = await api.get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error.response || error);
      return null;
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }

      const user = await getUser();
      if (!user) {
        logout();
        navigate("/login");
        return;
      }

      try {
        const token = getToken();
        const response = await api.get("/transactions", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTransactions(response.data.transactions);
        console.log("Transactions:", response.data.transactions);
      } catch (error) {
        console.error("Error fetching transactions:", error.response || error);
        if (error.response && error.response.status === 401) {
          logout();
          navigate("/login");
        } else {
          toast({
            title: "Error fetching transactions",
            description: error.response?.data?.message || "An error occurred",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
  }, [isAuthenticated, navigate, logout, toast]);

  const formatAmount = (amount) => {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const totalIncome = transactions
    .filter((t) => t.type === "income")
    .reduce((sum, t) => sum + t.amount, 0);

  const totalExpenses = transactions
    .filter((t) => t.type === "expense")
    .reduce((sum, t) => sum + t.amount, 0);

  const expensesByCategory = transactions
    .filter((t) => t.type === "expense")
    .reduce((acc, t) => {
      acc[t.category] = (acc[t.category] || 0) + t.amount;
      return acc;
    }, {});

  const pieChartData = Object.entries(expensesByCategory).map(
    ([category, amount]) => ({
      name: category,
      value: amount,
    })
  );

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box bg="white" p={2} borderRadius="md" boxShadow="md">
          <Text>{`${payload[0].name}: ${formatAmount(payload[0].value)}`}</Text>
        </Box>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
    <Box>
      <Heading mb={4}>Dashboard</Heading>
      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        <Box bg="white" p={4} borderRadius="md" boxShadow="md">
          <Heading size="md" mb={2}>
            Summary
          </Heading>
          <Text>Total Income: {formatAmount(totalIncome)}</Text>
          <Text>Total Expenses: {formatAmount(totalExpenses)}</Text>
          <Text>Balance: {formatAmount(totalIncome - totalExpenses)}</Text>
        </Box>
        <Box bg="white" p={4} borderRadius="md" boxShadow="md">
          <Heading size="md" mb={2}>
            Expenses by Category
          </Heading>
          {pieChartData.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {pieChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Text>No expense data available</Text>
          )}
        </Box>
      </SimpleGrid>
    </Box>
    </ErrorBoundary>
  );
};

export default Dashboard;
